import React, { useState } from 'react';
import PromotionProductsGroup from './ProductGroup';
import { useRegisterProductGift } from 'hooks/api/useArticles';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const PromotionProductsGroups = (props) => {
  const { products, dealInfo, queryKey, pageType, tecdocActive, actionId, registration_deadline } = props;
  const { slug } = useParams();

  const [activeProductId, setActiveProductId] = useState(null);

  const { mutate: registerPromotionProduct } = useRegisterProductGift(slug);

  const handleSelectProduct = (productId) => {
    const preparedData = { action_id: Number(actionId), item_id: productId };
    registerPromotionProduct(preparedData);
  };

  const handleActiveProduct = (id) => {
    setActiveProductId(id);
  };

  const groupedItems = Object.values(
    dealInfo.gifts.reduce((acc, item) => {
      const product = products.find((p) => item.item_id === p.item_id);

      if (!acc[item.group]) {
        const groupInfo = dealInfo.groups.find((g) => g.name === item.group);
        if (groupInfo) {
          acc[item.group] = {
            dealInfo: {
              tgift: groupInfo.tgift,
              title: groupInfo.name,
              turnover: dealInfo.turnover,
            },
            items: [],
          };
        } else {
          return acc;
        }
      }

      if (product) acc[item.group].items.push(product);
      return acc;
    }, {})
  );

  return (
    <div className="promo-products">
      {groupedItems.map((groupe, index) => {
        return (
          <PromotionProductsGroup
            key={index}
            prevLabel={dealInfo.prevLabel}
            goalLabel={dealInfo.goalLabel}
            dealInfo={groupe.dealInfo}
            accumulatedPoints={dealInfo.accumulatedPoints}
            groupTitle={groupe.dealName}
            products={groupe.items}
            tecdocActive={tecdocActive}
            pageType={pageType}
            queryKey={queryKey}
            handleSelectProduct={handleSelectProduct}
            handleActiveProduct={handleActiveProduct}
            activeProductId={activeProductId}
            selectedGiftId={dealInfo.registered}
            registration_deadline={registration_deadline}
          />
        );
      })}
    </div>
  );
};

export default PromotionProductsGroups;
