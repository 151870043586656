import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { compose } from 'redux';
import classNames from 'classnames';

import { articleKeys } from 'services/queryClient/queryKeys';
import { productTypes } from 'config/constants/productTypes';
import withAuth from 'HOC/withAuth/withAuth';
import withPageLayout from 'HOC/withPageLayout/withPageLayout';
import { useGetArticle, useGetArticleProducts } from 'hooks/api/useArticles';
import { useCatalogueTypes } from 'hooks/api/useProducts';
import useWindowDimensions from 'hooks/useWindowDimensions';
import QuickNavigation from 'components/Navigation/QuickNavigation/QuickNavigation';
import { GlobalPreloader, Preloader } from 'components/Preloader/Preloader';
import Icon, { ICON_NAMES } from 'components/Icon/index';

import { imgUrlToResizeUrl } from 'utils/imgUrlToResizeUrl';
import { salesDealTypes } from 'config/constants/dealTypes';
import Products from 'components/Products/Products';
import PromotionProductsGroups from 'components/PromotionProducts';
import { getFillValue } from 'utils/getAccumulativeSaleFillValue';

function SalesDetails() {
  const { data: catalogueTypes } = useCatalogueTypes();

  const { slug } = useParams();
  const { t } = useTranslation();
  const windowDimension = useWindowDimensions();

  const { data, isFetching } = useGetArticle({ slug });
  const image = data?.article?.data?.image;
  const title = data?.article?.data?.title;
  const date = moment(data?.article?.published_at).format('DD MMMM YYYY');
  const description = data?.article?.data?.description;
  const views = data?.article?.views;
  const actionId = data?.article?.action_id;
  const dealType = data?.article?.deal_type;
  const registration_deadline = data?.article?.registration_deadline;

  const isAccumulative = dealType === salesDealTypes['accumulative'];
  const isAccumulativeWithReg = dealType === salesDealTypes['accumulative_with_registration'];
  const isLoyalty = dealType === salesDealTypes['loyalty_program'];

  const isExpired = data?.article?.expires_in ? moment().unix() > moment(data.article.expires_in, 'YYYY-MM-DD hh:mm:ss').unix() : false;

  const { data: products, refetch: getProductsById, isLoading } = useGetArticleProducts(actionId);

  const percentFill = getFillValue(data?.article);

  useEffect(() => {
    if (actionId) getProductsById();
  }, [actionId, getProductsById]);

  return (
    <>
      {isFetching && <GlobalPreloader />}

      <div className="nav-bottom">
        <div className="nav-bottom__top-wrap">
          <div className="nav-bottom__left nav-bottom__item">
            <div className="title">
              {!isLoyalty && !isFetching && <h3>{t('page_title.sales_detail')}</h3>}
              {isLoyalty && !isFetching && <h3>{t('page_title.loyalty_program')}</h3>}
            </div>
          </div>

          {windowDimension.width > 760 && <QuickNavigation catalogueTypes={catalogueTypes} />}
        </div>
      </div>

      <div className="news-wrap">
        {data && (
          <div className="container">
            <div className="news-wrap__header">
              <div className="wrap">
                <div className="title">
                  <h1>{title}</h1>
                </div>
                <div className="publication">
                  <time className="publication__date">{date}</time>
                  <div className="publication__views-wrap">
                    <strong className="publication__views">{views}</strong>
                    <Icon name={ICON_NAMES.watched} />
                  </div>
                </div>
              </div>

              {isAccumulative && (
                <div className="bonuses">
                  <p>{t('label.your_current_bonus')}:</p>
                  <div className="bonuses__wrap">
                    <div className="indicator">
                      <span className="indicator__progress">{data?.article?.accumulated_points}</span>
                    </div>
                  </div>
                </div>
              )}
              {isAccumulativeWithReg && (
                <div className="bonuses bonuses-with-registration">
                  <p>
                    {t('label.your_current_spin')} {data?.article?.deal?.unioned && `(${t('label.shared_between_accounts')})`}:{' '}
                    {data?.article?.accumulated_points} {t('label.currency')}
                  </p>
                  {Boolean(data?.article?.deal?.registered) && (
                    <div className="progress-bar" style={{ '--progress': `${percentFill}%` }}>
                      <span className="bar"></span>
                      {percentFill}%
                    </div>
                  )}
                </div>
              )}

              {isLoyalty && (
                <div className="bonuses">
                  <p>{t('label.your_current_cashback')}:</p>
                  <div className="bonuses__wrap">
                    <div className="indicator">
                      <span className="indicator__progress">{data?.article?.accumulated_points}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <section className="news-detail">
              <div className={classNames('news-detail__img-wrap', { expired: isExpired })}>
                {isExpired && <div className="discount">{t('label.the_promotion_has_ended')}</div>}
                <img src={imgUrlToResizeUrl(image, 1920, 1047, 'resize')} alt="" />
              </div>
              <div className="news-detail__news-cnt news-cnt" dangerouslySetInnerHTML={{ __html: description }}></div>
            </section>
          </div>
        )}
      </div>

      {isLoading && (
        <div className="preloader-wrap">
          <Preloader />
        </div>
      )}

      {products && dealType === salesDealTypes.accumulative_with_registration && (
        <PromotionProductsGroups
          dealInfo={{
            ...data?.article?.deal,
            accumulatedPoints: data?.article?.accumulated_points,
            prevLabel: data?.article?.data?.previous_turnover_label,
            goalLabel: data?.article?.data?.goal_label,
          }}
          actionId={data?.article?.action_id}
          products={products}
          queryKey={articleKeys.articleProducts(actionId)}
          registration_deadline={registration_deadline}
        />
      )}
      {products && dealType !== salesDealTypes.accumulative_with_registration && (
        <div className="catalog-wrap">
          <Products products={products} queryKey={articleKeys.articleProducts(actionId)} productType={productTypes['article']} />
        </div>
      )}
    </>
  );
}

export default compose(withPageLayout(), withAuth())(SalesDetails);
