import 'react-toastify/dist/ReactToastify.css';
import './assets/style/style.scss';
import React, { useEffect } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';

import i18n from 'services/i18n/i18n';
import { useApi } from 'services/api';
import history from 'services/history';
import useSocket from 'hooks/api/useSocket';
import { useToken } from 'hooks/api/useAuth';
import withHandleError from 'HOC/withHandleError/withHandleError';
import withCommonData from 'HOC/withCommonData/withCommonData';
import ScrollTop from 'components/ScrollTop/ScrollTop';

import Login from 'pages/Login';
import PasswordRecovery from 'pages/PasswordRecovery';
import Main from 'pages/Main';
import Catalogue from 'pages/Catalogue';
import SearchBrands from 'pages/SearchBrands';
import SearchResultCode from 'pages/SearchResultCode';
import SearchResultName from 'pages/SearchResultName';
import OrderHistory from 'pages/OrderHistory';
import News from 'pages/News';
import Notification from 'pages/Notification';
import Favorites from 'pages/Favorites';
import NewsDetails from 'pages/NewsDetails';
import Sales from 'pages/Sales';
import SalesDetails from 'pages/SalesDetails';
import NotFound from 'pages/NotFound';
import Basket from 'pages/Basket';
import CreateNewPassword from 'pages/CreateNewPassword';
import Profile from 'pages/Profile';
import ProductDetails from 'pages/ProductDetails';
import Downloads from 'pages/Downloads';
import PaymentDetails from 'pages/PaymentDetails';
import Debts from 'pages/Debts';
import DebtsDetails from 'pages/DebtsDetails';
import OrderDetails from 'pages/OrderDetails';
import Report from 'pages/Report';
import TecdocSearch from 'pages/TecdocSearch';
import AboutUs from 'pages/AboutUs';
import LoyaltyPrograms from 'pages/LoyaltyPrograms';
import { useGetProfile } from 'hooks/api/useProfile';

function App() {
  const { data: token } = useToken();
  const { setToken, setContentLanguage } = useApi();

  const { data: user } = useGetProfile({ enabled: Boolean(token) });

  const locale = user?.settings?.locale;

  useEffect(() => {
    if (token) {
      setContentLanguage(locale);
      i18n.changeLanguage(locale);
      setToken(token);
    }
  }, [setToken, token, locale, setContentLanguage]);

  useSocket(token);

  return (
    <Router history={history}>
      <ScrollTop />
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/catalogue/:catalogueType/:category?" component={Catalogue} />
        <Route exact path="/tecdoc-search" component={TecdocSearch} />
        <Route exact path="/product-details/:id" component={ProductDetails} />
        <Route exact path="/search" component={SearchBrands} />
        <Route exact path="/search/result/code" component={SearchResultCode} />
        <Route exact path="/search/result/name" component={SearchResultName} />
        <Route exact path="/news" component={News} />
        <Route exact path="/news/:slug" component={NewsDetails} />
        <Route exact path="/sales" component={Sales} />
        <Route exact path="/sales/:slug" component={SalesDetails} />
        <Route exact path="/notification" component={Notification} />
        <Route exact path="/favorites" component={Favorites} />
        <Route exact path="/debts" component={Debts} />
        <Route exact path="/debt/:id" component={DebtsDetails} />
        <Route exact path="/basket" component={Basket} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/order-history" component={OrderHistory} />
        <Route exact path="/order/:id" component={OrderDetails} />
        <Route exact path="/downloads" component={Downloads} />
        <Route exact path="/payment-details" component={PaymentDetails} />
        <Route exact path="/report" component={Report} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/password-recovery" component={PasswordRecovery} />
        <Route exact path="/create-new-password/:token" component={CreateNewPassword} />
        <Route exact path="/about-us/:storage" component={AboutUs} />
        <Route exact path="/loyalty-programs" component={LoyaltyPrograms} />

        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default compose(withHandleError(), withCommonData())(App);
